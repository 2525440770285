<template>
  <div class="advcost-board-index">
    <headerNav title="门店经营月报" :leftIcon="true" @leftClick="back" >
      <template v-if="roleData.roleCode != 'ROLE_768022'">
        <span class="headNav-righ-text" @click="toMissionCenter">
          <img class="iconClass" src="../../../assets/a1.svg">任务中心
        </span>
      </template>
    </headerNav>
    <div class="main">
      <div class="search-box">
        <van-popover v-model="showPopoverMarket" trigger="click" :actions="marketActions" @select="onSelectMarket"
          :get-container="getContainer" placement="bottom">
          <template #reference>
            <van-button block type="info" color="#FFFFFF">{{ marketName }}<van-icon name="arrow-down" /></van-button>
          </template>
        </van-popover>
        <van-button block type="info" color="#FFFFFF" @click="openChooseDate">{{ month }}月<van-icon
            name="arrow-down" /></van-button>
        <!-- 选择月份 -->
        <van-popup v-model="showChooseDate" position="bottom" :style="{ height: '45%' }">
          <van-picker ref="datePicker" show-toolbar :columns="dateColumns" @confirm="onConfirmChooseDate"
            cancel-button-text=" " title="选择月份" :default-index="dateIndex" @cancel="showChooseDate = false" />
        </van-popup>
        <div class="updata-time">数据更新至{{ dataObj.updateDateMonth }}月{{ dataObj.updateDateDay }}日</div>
      </div>
      <div class="data-box">
        <div class="data-card">
          <div :class="index == 0 ? 'data-item bottom-line' : 'data-item'" v-for="(item, index) in dataObj.itemList"
            :key="item.name" v-if="index < 2">
            <div class="item-left">
              <div class="item-title">
                <img :src="item.icon" class="title-icon" alt="">
                {{ item.name }} <img src="" alt="">
                <span v-if="index == 0">费效比 : {{ Number(dataObj.scale.replace('%', '')).toFixed(2) }}%</span>
              </div>
              <div class="item-text">
                <div>
                  <p class="number" v-if="item.realityNum">{{ item.realityNum.toFixed(2) }}<span>{{ item.unit }}</span></p>
                  <p v-else class="number">--</p>
                  <p class="text" v-if="index == 0">完成任务</p>
                  <p class="text" v-else>花费费用</p>
                </div>
                <div>
                  <p class="number" v-if="item.taskNum">{{ item.taskNum.toFixed(2) }}<span>{{ item.unit }}</span></p>
                  <p v-else class="number">--</p>
                  <p class="text">最终任务</p>
                </div>
              </div>
            </div>
            <div class="item-right">
              <van-circle class="my-circle" v-model="item.scaleNumber" :rate="item.scaleNumber" :speed="100" text="88.00%"
                layer-color="#FFFFFF" :color="index == 1 ? '#FF8C21' : '#2CCCAE'" :stroke-width="100" fill="#F5F5F5">
                <div class="circle-text">{{ item.scale }}</div>
              </van-circle>
            </div>
          </div>
        </div>
        <div class="data-card1">
          <div class="data-item" v-for="(item, index) in dataObj.itemList"
            :key="item.name" v-if="index > 1">
            <div class="item-left">
              <div class="item-title">
                <img :src="item.icon" class="title-icon" alt="">
                {{ item.name }}
              </div>
              <div class="item-text">
                <div>
                  <p class="number">{{item.realityNum?item.realityNum:'--'}}<span>/{{ item.taskNum?item.taskNum:'--' }}{{ item.unit }}</span></p>
                  <p class="text">{{ item.scale }} <span>完成率</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="bg-icon" src="../../../assets/a39.svg" alt="">
    <div class="bottom-box">
      <van-button block type="info" color="#FF8C21" @click="toDataComparison">对比数据</van-button>
      <van-button block type="info" color="#3975C6" v-if="isOld" @click="toMissonAdjustmentRecord">调整记录</van-button>
      <van-button block type="info" color="#3975C6" v-else-if="isNow" @click="toMissonAdjustment">任务调整</van-button>

    </div>
  </div>
</template>

<script>
import api from '../../../api/api'
import moment from 'moment';

export default {
  data () {
    return {
      marketCode: '',
      marketName: '',
      showPopoverMarket: false,
      marketActions: [],
      showChooseDate: false,
      dateColumns: [],
      dateIndex: 0,
      year: moment().year(),
      month: (moment().month() + 1) < 10 ? '0' + (moment().month() + 1) : moment().month() + 1,
      dataObj: {},
      isOld: false,
      isNow:true,
      showHint: false,
      // 当前角色
      roleData:{},
    }
  },
  methods: {
    //返回
    back () {
      this.$router.push({
        name: 'TaskManagement'
      })
    },
    //前往任务中心
    toMissionCenter () {
      this.$router.push({ name: 'TaskAdvcostMissionCenter' })
    },
    //前往数据对比
    toDataComparison () {
      this.$router.push({
        name: 'TaskAdvcostDataComparison',
        params: {
          taskPeriod: this.year + '-' + this.month,
          marketCode: this.marketCode,
          marketName: this.marketName
        }
      })
    },
    //市场选择
    onSelectMarket (action) {
      this.marketName = action.name
      this.marketCode = action.code
      this.marketActions.forEach(e => {
        e.className = '';
        if (e.code == this.marketCode) {
          e.className = 'active-action'
        }
      })
      this.getBusinessData()
    },
    // 获取市场
    async getMarketCode () {
      let res = await api.getMarkets()
      this.marketCode = res.data?.length > 1 ? '370200' : res.data[0].code
      this.marketName = res.data?.length > 1 ? '青岛' : res.data[0].name
      this.marketActions = res.data.map(e => {
        e.text = e.name;
        e.className = '';
        if (e.code == this.marketCode) {
          e.className = 'active-action'
        }
        return e
      }) || []

    },
    getContainer () {
      return document.querySelector('.advcost-board-index');
    },
    //打开选择月份弹窗
    openChooseDate () {
      this.showChooseDate = true
      this.$nextTick(() => {
        this.$refs.datePicker.setValues([this.year, this.month])
      })
    },
    //选择月份
    onConfirmChooseDate (value) {
      this.year = value[0]
      this.month = value[1]
      this.getBusinessData()
      this.showChooseDate = false
    },
    //获取数据
    async getBusinessData () {
      let obj = {
        marketCode: this.marketCode,
        feeMonth: this.year + '-' + this.month
      }
      let res = await api.getBusinessData(obj)
      if (res.code == 200) {
        this.dataObj = res.data
        this.dataObj.itemList.forEach((e, i) => {
          if (e.scale == '--') {
            e.scaleNumber = 0
          } else {
            e.scaleNumber = Number(e.scale.replace('%', ''))
          }
          let iconUrl = require(`../../../assets/a${8 + i}.svg`)
          e.icon = iconUrl
        })
        this.dataObj.updateDateDay = moment(this.dataObj.updateDate).date()
        this.dataObj.updateDateMonth = moment(this.dataObj.updateDate).month() + 1
        this.isOld = this.isLessThanDate(this.year + '-' + this.month)
        this.isNow = this.month == ((moment().month() + 1) < 10 ? '0' + (moment().month() + 1) : moment().month() + 1)
      }
    },
    //判断是否小于当前月
    isLessThanDate (date) {
      let r = false
      if (moment(date).year() < moment(new Date()).year()) {
        r = true
      } else if (moment(date).year() == moment(new Date()).year()) {
        if (moment(date).month() < moment(new Date()).month()) {
          r = true
        }
      }
      return r
    },
    //前往任务调整
    toMissonAdjustment () {
      this.$router.push({
        name: 'TaskAdvcostMissionAdjustment',
        params: {
          taskPeriod: this.year + '-' + this.month,
          marketCode: this.marketCode,
          marketName: this.marketName
        }
      })
    },
    //前往调整记录
    toMissonAdjustmentRecord () {
      this.$router.push({
        name: 'TaskAdvcostMissionAdjustmentRecord',
        params: {
          taskPeriod: this.year + '-' + this.month,
          marketCode: this.marketCode,
          marketName: this.marketName
        },
        query: {
          isHistory: '1'
        }
      })
    }
  },
  mounted () { },
  created () {
    this.roleData = JSON.parse(localStorage.getItem("USER_ROLE"))
    let nowYear = moment().year()
    for (let i = nowYear - 10; i < nowYear + 10; i++) {
      let obj = {
        text: i,
        children: []
      }
      for (let i2 = 1; i2 < 13; i2++) {
        obj.children.push({
          text: i2 < 10 ? '0' + i2 : i2 + ''
        })
      }
      this.dateColumns.push(obj)
    }
    let _this = this
    this.getMarketCode().then(() => {
      _this.getBusinessData()
    })
  }
}
</script>

<style scoped lang="scss">
.advcost-board-index {
  position: relative;
  height: 100vh;
  overflow: auto;
  background: #F5F5F5;
}
.bg-icon{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.main{
  width: 100%;
  // height: calc(100vh - 130px);
  background: linear-gradient( 183deg, #3975C6 0%, rgba(57,117,198,0) 100%);
  position: relative;
  z-index: 2;
}
.search-box {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 20px;

  button {
    color: #1D2129 !important;
    width: 80px;
    height: 34px;
    border-radius: 4px 4px 4px 4px;
  }

  .van-icon {
    margin-left: 3px;
  }

  .updata-time {
    position: absolute;
    right: 8px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: rgba(255,255,255,0.8);
  }
}

.data-box {
  height: calc(100vh - 200px);
  overflow: auto;
  padding: 0 10px;
}

.bottom-box {
  background: #FFFFFF;
  display: flex;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 12px;
  align-items: center;
  padding-bottom: 20px;
  button {
    border-radius: 4px;
    font-size: 15px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}

.bottom-box {
  height: 90px;
  position: relative;
  z-index: 1;
}

.data-card {
  margin-bottom: 10px;
  border-radius: 8px;
  background: #FFFFFF;

  .data-item {
    display: flex;
    .item-left {
      width: 70%;

      .item-title {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #1D2129;
        display: flex;
        align-items: center;
        padding: 12px 12px;
        padding-right: 0;

        &>span {
          font-size: 13px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #999999;
          margin-left: 8px;
        }

        .title-icon {
          width: 25px;
          margin-right: 8px;
          margin-top: 1px;
        }
      }

      .item-text {
        display: flex;
        justify-content: space-evenly;
        &>div {
          width: 45%;
          margin-bottom: 10px;
          background: rgba(242,242,242,0.3);
          border-radius: 4px 4px;

          p {
            margin: 0;
          }

          .number {
            font-size: 18px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            color: #1D2129;
            line-height: 35px;

            span {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #545454;
            }
          }

          .text {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #545454;
            padding-bottom: 4px;
          }
        }
      }
    }

    .item-right {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #F5F5F5;

      .my-circle {
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .bottom-line {
    border-bottom: 1px solid #3975C6;
  }

  .circle-text {
    font-size: 16px;
    font-family: Bahnschrift, Bahnschrift;
    font-weight: 400;
    color: #1D2129;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: #FFFFFF;
    z-index: 1;
    line-height: 60px;
    text-align: center;
  }
}

.data-card1{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px;
  .data-item {
    display: flex;
    width: 48.5%;
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 10px;
    .item-left {
      width: 100%;
      .item-title {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #999999;
        display: flex;
        align-items: center;
        margin: 8px 24px 0px;
        padding-bottom: 7px;
        border-bottom: 1px solid #EEEEEE;
        padding-right: 0;
        .title-icon {
          width: 25px;
          margin-right: 4px;
          margin-top: 1px;
        }
      }

      .item-text {
        &>div {
          flex: 1;

          p {
            margin: 0;
            text-align: left;
            margin-left: 24px;
          }
          .number {
            font-size: 24px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 35px;
            margin-top: 8px;
            margin-bottom: 4px;
            span {
              font-size: 18px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }

          .text {
            font-size: 15px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #0084F4;
            padding-bottom: 8px;
            span {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 13px;
              color: #999999;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

.hint-icon {
  width: 17px;
  margin-left: 2px;
  margin-bottom: -4px;
}

.hint-text {
  line-height: 25px;
  padding: 0 10px;
}

::v-deep {
  .active-action {
    background: #3DA2FF;
    color: #FFFFFF;
    font-weight: bold;
  }

  .iconClass {
    margin-bottom: -2px;
    color: red;
    margin-right: 2px;
  }

  .van-popover__action {
    padding: 0;
    width: 80px;
    height: 40px;
  }

  .van-popover__arrow {
    display: none;
  }
}</style>